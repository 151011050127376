import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'users',
        loadChildren: () => import('../../users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'customer-locations',
        loadChildren: () => import('../../customer-locations/customer-location.module').then(m => m.CustomerLocationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'installers',
        loadChildren: () => import('../../installers/installers.module').then(m => m.InstallersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'installer-chains',
        loadChildren: () => import('../../installer-chains/installer-chains.module').then(m => m.InstallerChainsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'equipment',
        loadChildren: () => import('../../equipment/equipment.module').then(m => m.EquipmentModule),
        canActivate: [AuthGuard]
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardLayoutRoutingModule { }
