<!-- <mat-card class="mat-elevation-z3" style="border-style: none;"> -->
<mat-card class="p-0 m-0 mat-elevation-z7 content" >
    <mat-nav-list class="p-0 m-0" style="overflow-y: auto;font-family: Montserrat, sans-serif; font-weight: 600">
      <mat-list-item class="p-2"
        *ngFor="let item of customPresets"
        (click)="selectRange(item)"
        [ngClass]="{ 'preset': item === selectedRange }"
      >
        {{ 'website.datepicker_range_preset.'+item | translate }}
      </mat-list-item>
      <mat-list-item [disableRipple]="true"  class="p-2 text-break break-line text-dark" ><div class="break-line ">
        {{ selectedDateRange  }}
      </div>

    </mat-list-item>
    </mat-nav-list>
</mat-card>
