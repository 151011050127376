import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import _ from 'lodash';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() label = 'Autocomplete';
  @Input() options: any[] = [];
  @Input() control: FormControl ;
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() loadingInProgress = false;
  @Input() customDisplayFn: (options: any[], value: any) => string|null;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() classList: string;
  @Input() disabled = false;
  @Input() requiredSelection: boolean=false;
  @Output() selectEvent = new EventEmitter<MatAutocompleteSelectedEvent>();

  filteredOptions: Observable<any[]>;
  private isInternalControl = false;
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (!this.control){
        this.control =    new FormControl();
        this.isInternalControl = true;
    }

    this.initializeFilter();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.initializeFilter();
      if (this.control?.value){
        this.control.patchValue(this.control.value);
      }

    }

    if (changes.customDisplayFn) {
      this.customDisplayFn = changes.customDisplayFn?.currentValue;
    }

  }

  displayFn = (option: any) => {
    if (typeof this.customDisplayFn === 'function') {
      return this.customDisplayFn(this.options, option);
    }

    if (typeof option === 'object') {
      return this.getValueFromPathObject(option, this.bindLabel);
    }

    return option;
  }


  public optionSelectedEvent($event: MatAutocompleteSelectedEvent): void {
    if (this.isInternalControl){
      this.control.patchValue(null);
    }
    this.selectEvent.emit($event);
  }

  private initializeFilter(): void {
    this.filteredOptions = this.control?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      startWith(''),
      map(value => {
        const displayValue = typeof value === 'string' ? value : this.getValueFromPathObject(value, this.bindLabel);
        return displayValue ? this._filter(displayValue as string) : this.options?.slice();
      }),
    );
  }

  private _filter(value: string): any[] {
    const filterValue = value?.toLowerCase();

    return this.options?.filter(option => {
      const val = typeof option === 'string' ? option : this.getValueFromPathObject(option, this.bindLabel) ;
      return val?.toLowerCase().includes(filterValue) ;
    });
  }

  public getValueFromPathObject(option: any, path: string): any {
    if (typeof option === 'string' || !path){
      return option;
    }
    return _.get(option, path);
  }
}
