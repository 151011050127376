
// CAUTION: auto generated by `set-env.ts`
export const environment = {
  production: true,
  apiPort:'',
  apiUrl: '/{{lang}}/graphql',
  mockApiUrl: 'undefined',
  localeUrl: '/{{lang}}/locale',
  kickstartFrontendPort: '',
  appVersion: 'build.2024-09-17.',
  langsPresentationOrder: ['fr', 'en'],
  urlPrefix: '/admin'
};
