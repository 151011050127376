import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressAutocompleteService {

  constructor(private http: HttpClient) { }

  search(query: string): Observable<any> {
    const url = `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&access_token=pk.eyJ1IjoicmFuaWtoYXJzYSIsImEiOiJjbTBjaDI3cHEwMjgwMm1zY3M5aXhncmdxIn0.eovLyOOE2JRycBC3WWVF9Q&limit=5&language=fr&types=address`;
    return this.http.get(url);
  }

  searchBy(osmid: string): Observable<any> {
    const url = `https://nominatim.openstreetmap.org/details.php?osmtype=N&osmid=${osmid}&class=place&addressdetails=1&hierarchy=0&group_hierarchy=1&polygon_geojson=1&format=json`;
    return this.http.get(url);
  }
}
